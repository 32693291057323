import React from 'react'
import {DownloadAppButtonStoryblok} from 'types/storyblok-types'
import DownloadAppButtonBlock from './DownloadAppButtonBlock'

type DownloadAppButtonBlockProps = {
  blok: DownloadAppButtonStoryblok
  full_width?: boolean
}

function DownloadAppButtonBlockContainer({
  blok,
  full_width,
}: DownloadAppButtonBlockProps) {
  return (
    <DownloadAppButtonBlock
      title={blok.title ?? ''}
      text={blok.text ?? ''}
      imageFileName={blok.image?.filename}
      imageAlt={blok.image?.alt}
      link={blok.link}
      blok={blok}
      full_width={full_width ?? false}
    />
  )
}

export default DownloadAppButtonBlockContainer
