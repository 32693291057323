import React from 'react'
import Image from 'next/image'
import {storyblokEditable} from '@storyblok/react'
import {
  DownloadAppButtonStoryblok,
  MultilinkStoryblok,
} from 'types/storyblok-types'
import Link from 'next/link'

type DownloadAppButtonBlockProps = {
  title: string
  text: string
  imageFileName?: string
  imageAlt?: string
  link?: MultilinkStoryblok
  blok: DownloadAppButtonStoryblok
  full_width: boolean
}

function DownloadAppButtonBlock({
  title,
  text,
  imageFileName,
  imageAlt,
  link,
  blok,
  full_width,
}: DownloadAppButtonBlockProps) {
  return (
    <Link href={link?.cached_url ?? '#'} className="no-underline">
      <button
        type="button"
        className={`block w-full lg:flex ${
          full_width ? 'lg:w-full' : 'lg:w-auto'
        }`}
        {...storyblokEditable(blok)}
      >
        <div
          className={`flex h-[81px] w-full items-center space-x-2 rounded-dats border border-dats-border-gray bg-white px-[15px] py-4 hover:bg-dats-s4 ${
            full_width ? 'lg:w-full' : 'lg:w-[280px]'
          }`}
        >
          {imageFileName ? (
            <div className="">
              <Image
                src={imageFileName}
                alt={imageAlt ?? ''}
                style={{
                  width: '48px',
                  height: '100%',
                }}
                width={44}
                height={0}
                // fill
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
              />
            </div>
          ) : null}

          <div className="flex flex-col items-start">
            <p className="font-rubik text-[12px] text-dats-s3">{title}</p>
            <p className="font-rubik text-[16px] font-medium text-dats-s3">
              {text}
            </p>
          </div>
        </div>
      </button>
    </Link>
  )
}

export default DownloadAppButtonBlock
