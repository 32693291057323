import React from 'react'
import {SdpOpeningHoursStoryblok} from 'types/storyblok-types'
import Image from 'next/image'
import {storyblokEditable} from '@storyblok/react'
import Link from 'next/link'
import linkTypeChecker from 'util/linkTypeChecker'

type SDPOpeningHoursContainerPropsType = {
  blok: SdpOpeningHoursStoryblok
}

type SDPOpeningHoursBlokPropsType = {
  title: SdpOpeningHoursStoryblok['title']
  text: string
  cta: SdpOpeningHoursStoryblok['cta']
  blok: SdpOpeningHoursStoryblok
}

export function SDPOpeningHoursBlok({
  title,
  text,
  cta,
  blok,
}: SDPOpeningHoursBlokPropsType) {
  return (
    <Link
      href={linkTypeChecker(cta) ?? '#'}
      className="flex h-auto w-full flex-col space-y-8 rounded-dats bg-dats-s10 p-8 no-underline"
      {...storyblokEditable(blok)}
    >
      <div className="font-rubik text-base font-bold text-dats-s5">{title}</div>
      <div className="flex w-full">
        <div className="w-auto ">
          <p className="font-sans text-base font-normal text-dats-s5">{text}</p>
        </div>
        <Image
          src="/images/alert/alert_chevron.svg"
          alt={''}
          width={13}
          height={12}
          className="mx-2 hidden pt-0.5 md:block"
        />
      </div>
    </Link>
  )
}

const SDPOpeningHoursContainer = ({
  blok,
}: SDPOpeningHoursContainerPropsType) => {
  return (
    <SDPOpeningHoursBlok
      title={blok.title}
      text={blok.text}
      cta={blok.cta}
      blok={blok}
    />
  )
}

export default SDPOpeningHoursContainer
